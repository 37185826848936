<template>
  <b-container :show="$apollo.loading">
    <b-tabs
      v-if="!$apollo.loading && showSettingsPage"
      vertical
      nav-wrapper-class="col-3"
      nav-class="mt-5"
      v-model="tabIndex"
    >
      <template #tabs-start>
        <h3>{{ $t('settings.settings') }}</h3>
      </template>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_ROOT_SETTINGS'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        ) && isRootFolder"
        @click="setUrl('')"
      >
        <template #title>{{ $t('settings.folder.group.tabName') }}</template>
        <folder-settings-domain
          class="mt-5"
          :group-id="groupId"
          :group-name="groupName"
          :group-domain="groupDomain"
          :group-slug="groupSlug"
          :is-sys-admin="userObject.isSysAdmin"
          :pirsch-code="pirschCode"
        ></folder-settings-domain>
      </b-tab>
      <b-tab v-if="!isRootFolder && $hasPrivileges(
          $privileges('CAN_EDIT_SETTINGS_PUBLICATION'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )">
        <template #title>{{ $t('settings.folder.tabName') }}</template>
        <folder-settings-publications
          class="mt-5"
          :sibling-slugs="allChildSlugsOfParentFolder"
          :folders-data="folderData"
          @updateFolderSettings="updateFolderPublicationSettings"
        ></folder-settings-publications>
      </b-tab>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_SETTINGS_CI'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
        :ref="$t('settings.folder.corporateIdentity.hash')"
        @click="setUrl($t('settings.folder.corporateIdentity.hash'))"
      >
        <template #title>{{ $t('settings.folder.corporateIdentity.tabName') }}</template>
        <folder-settings-corporate-identity
          v-if="!$apollo.loading"
          class="mt-5"
          :custom-group-fonts="customGroupFonts"
          :is-super-admin="userObject.isSysAdmin"
          :corporate-identity-data="corporateIdentity"
          @update-ci-settings="updateCISettings"
          @override-children="overrideChildrenWithCI"
          @override-all-children="overrideAllCIChildren"
        ></folder-settings-corporate-identity>
      </b-tab>
      <b-tab
        v-if="$hasPrivileges(
          $privileges('CAN_EDIT_SETTINGS_PLUGINS'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )"
        :ref="$t('settings.plugins.hash')"
        @click="setUrl($t('settings.plugins.hash'))"
      >
        <template #title>{{ $t('settings.plugins.tabName') }}</template>
        <plugin-list
          class="mt-5"
          :key="componentKey"
          :folder-id="currentItemObject.id"
          :is-sys-admin="userObject.isSysAdmin"
          :privileges="currentItemObject.privilegesForItem"
          @update-plugin-settings="updatePluginSettings"
          @override-all-children="overrideAllPluginChildren"
          @override-children="overrideChildrenWithPlugin"
        ></plugin-list>
      </b-tab>
      <b-tab v-if="$hasPrivileges(
          $privileges('CAN_EDIT_CUSTOM_CSS'),
          userObject.isSysAdmin,
          currentItemObject.privilegesForItem,
        )" :ref="$t('settings.customCss.hash')" @click="setUrl($t('settings.customCss.hash'))"
             lazy
      >
        <template #title>Custom CSS</template>
        <b-row class="mb-4 mt-5">
          <b-col cols="12" offset-sm="1" sm="10">
            <h3>
              {{ $t('settings.customCss.tabTitle') }}
            </h3>
            <hr>
            <p>
              {{ $t('settings.customCss.tabDesc') }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mb-4 pb-4">
          <b-col cols="12" offset-sm="1" sm="10">
            <label>
              {{ $t('settings.customCss.customCssLabel') }}
            </label>
          </b-col>
          <b-col cols="12" offset-sm="1" sm="10">
            <custom-css-editor
              :cssCode="customCss"
              @update-cssCode="updateCustomCss"
            />
            <b-form-text>
              {{ $t('settings.customCss.customCssDesc') }}
            </b-form-text>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab lazy :ref="$t('settings.analytics.hash')"
             @click="setUrl($t('settings.analytics.hash'))">
        <template #title>{{ $t('settings.analytics.usefathomTabName') }}</template>
        <usefathom-analytics-dashboard
          v-if="slugPathFromUrl && groupDomain && currentItemObject"
          :group-domain="groupDomain"
          :slug-path-from-url="slugPathFromUrl"
          :item-id="currentItemObject.id"
          item-type="folder"
          :showReadersInTable="showReadersInTable"
          :isSysAdmin="userObject.isSysAdmin"
        />
      </b-tab>
      <b-tab lazy
             v-if="userObject.isSysAdmin"
             :ref="'pirsch'"
             @click="setUrl('pirsch')"
      >
        <template #title>{{ $t('settings.analytics.pirschTabName') }}</template>
        <pirsch-analytics-dashboard
          v-if="currentItemObject"
          :item-id="currentItemObject.id"
          item-type="folder"
          :pirschCode="pirschCode"
          :showReadersInTable="showReadersInTable"
          :isSysAdmin="userObject.isSysAdmin"
        />
      </b-tab>
      <b-tab lazy :ref="'stats'"
        v-if="userObject.isSysAdmin"
        @click="setUrl('stats')">
        <template #title>{{ $t('settings.analytics.tabName') }}</template>
        <merged-analytics-dashboard
          v-if="slugPathFromUrl && groupSlug && groupDomain && currentItemObject && pirschCode"
          :group-domain="groupDomain"
          :group-slug="groupSlug"
          :slug-path-from-url="slugPathFromUrl"
          :item-id="currentItemObject.id"
          :item-type="currentItemObject.type"
          :pirsch-code="pirschCode"
        />
        <div v-else-if="!pirschCode">
          <b-row class="row mb-4 mt-5">
            <b-col cols="12" offset-sm="1" sm="10">
              {{ $t('settings.analytics.noPirschCode') }}
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
    <modal-leave-page ref="leaveModalWindow"/>
  </b-container>
</template>

<script>
import InsertPlugin from '@/graphQlQueries/mutations/insertPlugin';
import DeletePluginEntryForFolder from '@/graphQlQueries/mutations/deletePluginEntryForFolder';
import DeletePluginEntryForProject from '@/graphQlQueries/mutations/deletePluginEntryForProject';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import SetShowHeaderInCache from '@/graphQlQueries/mutations/setShowHeaderInCache';
import UpdateFolderPlugin from '@/graphQlQueries/mutations/updateFolderPlugin';
import GetAllItemsOfFolderById from '@/graphQlQueries/queries/getAllItemsOfFolderById';
import GetAllPluginDataOfFolder from '@/graphQlQueries/queries/getAllPluginDataOfFolder';
import GetDataHasChangedForSavingFromCache from '@/graphQlQueries/queries/getDataHasChangedForSavingFromCache';
import GetFolderSettingsById from '@/graphQlQueries/queries/getFolderSettingsById';
import SetFolderSettings from '@/graphQlQueries/mutations/setFolderSettings';
import SetPluginPasswordProtection from '@/graphQlQueries/mutations/setPluginPasswordProtection';
import UpdateFoldersCIData from '@/graphQlQueries/mutations/updateFoldersCIData';
import UpdateProjectsCIData from '@/graphQlQueries/mutations/updateProjectsCIData';
import GetGroupSlugByGroupId from '@/graphQlQueries/queries/getGroupSlugByGroupId';
import GetAllDescendantsOfItem from '@/graphQlQueries/queries/getAllDescendantsOfItem';
import DeleteCacheFetchMagazine from '@/graphQlQueries/mutations/deleteCacheFetchMagazine';
import BeforeLeavingRouteOpenModal from '@/mixins/beforeLeavingRouteOpenModal';
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import GetItemFromUrl from '@/mixins/getItemFromUrl';
import CheckAccessForTeamUser from '@/mixins/checkAccessForTeamUser';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import SetDataChangedInCache from '@/mixins/setDataChangedInCache';
import { isEqual, isEmpty } from 'lodash';

export default {
  name: 'FolderSettings',
  mixins: [
    BeforeLeavingRouteOpenModal,
    UserDataAndAccess,
    GetItemFromUrl,
    CheckAccessForTeamUser,
    SetDataChangedInCache,
    SendPublishedDataToServer,
  ],
  components: {
    FolderSettingsCorporateIdentity: () => import('@/components/settings/folders/FolderSettingsCorporateIdentity.vue'),
    FolderSettingsDomain: () => import('@/components/settings/folders/FolderSettingsDomain.vue'),
    FolderSettingsPublications: () => import('@/components/settings/folders/FolderSettingsPublications.vue'),
    ModalLeavePage: () => import('@/components/modals/ModalLeavePage.vue'),
    PluginList: () => import('@/components/settings/PluginList.vue'),
    CustomCssEditor: () => import('@/components/helper/CustomCssEditor.vue'),
    UsefathomAnalyticsDashboard: () => import('@/components/settings/analytics/UsefathomAnalyticsDashboard.vue'),
    PirschAnalyticsDashboard: () => import('@/components/settings/analytics/PirschAnalyticsDashboard.vue'),
    MergedAnalyticsDashboard: () => import('@/components/settings/analytics/MergedAnalyticsDashboard.vue'),
  },
  props: {
    parametersOfUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    pageType: 'folder-settings',
    slugPathFromUrl: null,
    folderSettings: {},
    corporateIdentity: {},
    groupSlug: null,
    corporateIdentityOriginal: {},
    folderData: {
      name: null,
      slug: null,
    },
    overrideChildrenPlugin: {},
    customCss: null,
    customCssOriginal: null,
    folderDataOriginal: null,
    oldSlug: null,
    parentSlugPath: null,
    isRootFolder: false,
    groupName: null,
    groupDomain: null,
    customGroupFonts: null,
    folderName: null,
    updatedPluginData: {},
    pluginDataFromDB: {},
    componentKey: 0,
    overrideChildrenCI: {},
    parentFolderIdOfFolder: null,
    allChildSlugsOfParentFolder: null,
    runfolderSettings: true,
    tabIndex: 0,
    analyticsWebmagPluginActivated: false,
    analyticsWebmagPluginCookiesActivated: false,
    urlHash: {
      analytics: 0,
    },
    pirschCode: null,
  }),
  computed: {
    showReadersInTable() {
      return !!this.analyticsWebmagPluginCookiesActivated;
    },
    folderTabTitle() {
      return (this.isRootFolder)
        ? 'Folder'
        : 'domain';
    },
    showSettingsPage() {
      if (this.currentItemObject && this.userObject) {
        if (this.isRootFolder) {
          return this.$hasPrivileges(
            this.$privileges('CAN_EDIT_ROOT_SETTINGS'),
            this.userObject.isSysAdmin,
            this.currentItemObject.privilegesForItem,
          );
        }
        return this.$hasPrivileges(
          this.$privileges('CAN_EDIT_FOLDER_SETTINGS'),
          this.userObject.isSysAdmin,
          this.currentItemObject.privilegesForItem,
        );
      }
      return false;
    },
  },
  created() {
    this.setTypeOfPageInApolloCache();
    this.isRootFolder = !(this.parametersOfUrl); // checks if parametersOfUrl is NULL
    if (this.parametersOfUrl) {
      this.slugPathFromUrl = `/${this.parametersOfUrl}`;
    } else {
      // use #__DASHBOARD__# to identify the root folder
      this.slugPathFromUrl = '#__ROOT_FOLDER__#';
    }
    // remove the last parameter of the slugpath
    if (!this.isRootFolder) {
      const positionOfLastSlash = this.parametersOfUrl.lastIndexOf('/');
      this.parentSlugPath = (this.parametersOfUrl || positionOfLastSlash !== -1)
        ? this.parametersOfUrl.substring(0, positionOfLastSlash)
        : null;
    }
  },
  // We need to activate the tab here because DOM elements are not rendered in 'created()'
  async mounted() {
    // This is to remove the '#' from the string
    const hash = this.$route.hash.substr(1);
    if (hash === this.$t('settings.folder.corporateIdentity.hash')) {
      // eslint-disable-next-line max-len
      // interval here is needed as we need to wait for the sub component 'b-tab' to finish rendering
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.folder.corporateIdentity.hash')]) {
          this.$refs[this.$t('settings.folder.corporateIdentity.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === this.$t('settings.plugins.hash')) {
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.plugins.hash')]) {
          this.$refs[this.$t('settings.plugins.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === this.$t('settings.customCss.hash')) {
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.customCss.hash')]) {
          this.$refs[this.$t('settings.customCss.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === this.$t('settings.analytics.hash')) {
      const interval = setInterval(() => {
        if (this.$refs[this.$t('settings.analytics.hash')]) {
          this.$refs[this.$t('settings.analytics.hash')].activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === 'pirsch') {
      const interval = setInterval(() => {
        if (this.$refs.pirsch) {
          this.$refs.pirsch.activate();
          clearInterval(interval);
        }
      }, 50);
    } else if (hash === 'stats') {
      const interval = setInterval(() => {
        if (this.$refs.stats) {
          this.$refs.stats.activate();
          clearInterval(interval);
        }
      }, 50);
    }
  },
  apollo: {
    groupSlug: {
      query: GetGroupSlugByGroupId,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        if (data.groups.length) {
          this.groupName = data.groups[0].name;
          this.groupDomain = data.groups[0].domain;
          this.pirschCode = data.groups[0].pirsch_code;
          this.customGroupFonts = [];
          if (data.groups[0].settings?.customFonts) {
            Object.entries(data.groups[0].settings.customFonts)
              .forEach((fontArray) => {
                let customFontFamily = fontArray[1].fontFamily;
                // check for quotes in font name
                if (/^'.*'$/.test(customFontFamily)) {
                  customFontFamily = customFontFamily.substring(1, customFontFamily.length - 1);
                }
                if (!this.customGroupFonts.includes(customFontFamily)) {
                  this.customGroupFonts.push(customFontFamily);
                }
              });
          }
          return data.groups[0].slug;
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.groupId;
      },
    },
    allChildSlugsOfParentFolder: {
      query: GetAllItemsOfFolderById,
      variables() {
        return {
          id: this.parentFolderIdOfFolder,
        };
      },
      update(data) {
        const slugsOfAllChildItems = [];
        if (data.folders && data.folders.length > 0) {
          const folder = data.folders[0];

          folder.child_folders.forEach((childFolder) => {
            if (this.folderData.slug !== childFolder.slug) {
              slugsOfAllChildItems.push(childFolder.slug);
            }
          });

          folder.child_projects.forEach((project) => {
            slugsOfAllChildItems.push(project.slug);
          });

          folder.child_pages.forEach((page) => {
            slugsOfAllChildItems.push(page.slug);
          });
        }
        return slugsOfAllChildItems;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.parentFolderIdOfFolder || !this.folderData.slug;
      },
    },
    folderSettings: {
      query: GetFolderSettingsById,
      variables() {
        return {
          id: this.currentItemObject.id,
        };
      },
      update(data) {
        if (data.folders.length) {
          const folder = data.folders[0];
          // folder data
          this.folderName = folder.name;
          this.folderData.name = folder.name;
          this.folderData.slug = folder.slug;
          this.folderData.listed = folder.listed;
          this.folderData.language_id = folder.language_id;
          this.parentFolderIdOfFolder = folder.parent_folder_id;
          this.folderDataOriginal = {
            name: folder.name,
            slug: folder.slug,
            listed: folder.listed,
            language_id: folder.language_id,
          };
          this.oldSlug = folder.slug;
          this.customCss = folder.custom_css;
          this.customCssOriginal = folder.custom_css;
          this.corporateIdentity = { ...folder.corporate_identity };
          this.corporateIdentityOriginal = { ...folder.corporate_identity };
          this.runfolderSettings = false;
          return folder;
        }
        this.runfolderSettings = false;
        return null;
      },
      skip() {
        return !this.currentItemObject || (this.currentItemObject && this.currentItemObject.type !== 'folder') || !this.runfolderSettings;
      },
      fetchPolicy: 'network-only',
    },
    hasDataForSavingChangedObject: {
      query: GetDataHasChangedForSavingFromCache,
      update(data) {
        if (data.dataHasChangedForSaving && data.dataHasChangedForSaving.isSubmittedButtonPressed) {
          this.updateFolderSettings();
        }
        if (data.dataHasChangedForSaving && data.dataHasChangedForSaving.isCancelButtonPressed) {
          this.resetSettings();
        }
        return data;
      },
    },
    pluginDataFromDB: {
      query: GetAllPluginDataOfFolder,
      variables() {
        return {
          folderId: this.currentItemObject.id,
        };
      },
      update(data) {
        const pluginDataOfDb = {};
        data.plugins.forEach((dataItem) => {
          pluginDataOfDb[dataItem.handle] = {
            enabled: dataItem.enabled,
            settings: dataItem.settings,
          };
        });
        this.checkForAnalyticsDashboard(pluginDataOfDb);
        return pluginDataOfDb;
      },
      skip() {
        return !this.currentItemObject;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    setUrl(hash) {
      const regex = /#.*$/ig;
      let url = '';
      if (regex.test(this.$route.fullPath)) {
        url = this.$route.fullPath.replace(regex, (hash !== '') ? `#${hash}` : '');
      } else {
        url = this.$route.fullPath.concat((hash !== '') ? `#${hash}` : '');
      }
      window.history.pushState({}, '', url);
    },
    checkForAnalyticsDashboard(pluginData) {
      if (
        pluginData.plugin_analytics_webmag
        && pluginData.plugin_analytics_webmag.enabled === true
      ) {
        this.analyticsWebmagPluginActivated = true;
      }
      if (
        pluginData.plugin_analytics_webmag_cookies
        && pluginData.plugin_analytics_webmag_cookies.enabled === true
      ) {
        this.analyticsWebmagPluginCookiesActivated = true;
      }
    },
    showLeaveModal() {
      this.$refs.leaveModalWindow.$refs.leaveModal.show();
    },
    resetSettings() {
      this.resetCacheForHeaderButtons();
      this.runfolderSettings = true;
      this.$apollo.queries.groupSlug.refresh();
      this.$apollo.queries.folderSettings.refresh();
    },
    resetCacheForHeaderButtons() {
      this.setDataChangedInCache({
        pageType: this.pageType,
        isDataChanged: false,
        isSubmittedButtonPressed: false,
        isCancelButtonPressed: false,
        isDataPublished: false,
      });
    },
    setTypeOfPageInApolloCache() {
      this.$apollo.mutate({
        mutation: SetPageTypeInCache,
        variables: {
          type: this.pageType,
        },
      });
      this.$apollo.mutate({
        mutation: SetShowHeaderInCache,
        variables: {
          show: true,
        },
      });
    },
    updateCustomCss(customCss) {
      let hasDataChanged = false;
      this.customCss = customCss;
      if (this.customCssOriginal === null && customCss !== '') {
        hasDataChanged = true;
      }
      if (this.customCssOriginal !== null && customCss !== this.customCssOriginal) {
        hasDataChanged = true;
      }
      this.folderSettingsChanged(hasDataChanged);
    },
    async updateFolderSettings() {
      try {
        const folderId = this.currentItemObject.id;
        const { oldSlug } = this;
        const folderObject = {
          slug: this.folderData.slug,
          name: this.folderData.name,
          listed: this.folderData.listed,
          language_id: this.folderData.language_id,
          corporate_identity: { ...this.corporateIdentity },
        };
        const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: 'updateFolderSettings',
          data: {
            old_slug: oldSlug,
            parent_slug_path: `/${this.parentSlugPath}`,
            group_slug: this.groupSlug,
            ...folderObject,
          },
        });
        if (publishedDataWasSuccessfulSend) {
          await this.saveAllChildrenCIValues();
          await this.saveAllChildrenPluginValues();
          // after the data was published add the custom_css to write into the database
          folderObject.custom_css = this.customCss;
          // because of performance reasons we remove the name and slug
          // from the object if it did not change
          if (oldSlug === this.folderData.slug) {
            delete folderObject.slug;
          }
          if (this.folderDataOriginal.name === this.folderData.name) {
            delete folderObject.name;
          }
          await this.$apollo.mutate({
            mutation: SetFolderSettings,
            variables: {
              folderObject,
              folderId,
            },
            update: (cache) => {
              const cachedFolderSettings = cache.readQuery({
                query: GetFolderSettingsById,
                variables: { id: folderId },
              });
              const newFolderSettingsForCache = [
                {
                  ...cachedFolderSettings.folders[0],
                  ...folderObject,
                },
              ];
              cache.writeQuery({
                query: GetFolderSettingsById,
                variables: { id: folderId },
                data: { folders: newFolderSettingsForCache },
              });
            },
          });
          await this.savePluginSettingsInDB();
          // delete cache
          const parentSlugPath = (this.parentSlugPath === null) ? '' : this.parentSlugPath;
          const domain = this.groupDomain;
          let slug_path = (oldSlug === null) ? '' : `${parentSlugPath ? `${parentSlugPath}/` : ''}${oldSlug}`;
          slug_path = slug_path.substring(0, slug_path.lastIndexOf('/'));

          await this.$apollo.mutate({
            mutation: DeleteCacheFetchMagazine,
            variables: {
              domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
              slug_path,
            },
          });
          if (oldSlug !== this.folderData.slug) {
            this.redirectToNewPageAfterSlugChange(oldSlug);
          }
          // reset the data
          this.resetSettings();
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    redirectToNewPageAfterSlugChange(oldSlug) {
      try {
        // build the new url by replacing the old slug with the new slug
        const path = this.$route.params.parametersOfUrl;
        const pos = path.lastIndexOf(oldSlug);
        const redirectPath = `/${path.substring(0, pos)}${this.folderData.slug}/folder-settings`;
        // we need the timeout because the item_lookup table has to be updated
        setTimeout(() => {
          this.$router.push({ path: redirectPath });
        }, 1000);
      } catch (e) {
        console.error('Error while redirecting to new folder settings after slug change', e);
      }
    },
    async saveAllChildrenPluginValues() {
      const allCurrentPluginData = {
        ...this.pluginDataFromDB,
        ...this.updatedPluginData,
      };
      if (!isEmpty(this.overrideChildrenPlugin)) {
        const childrenArray = await this.getAllDescendentItems();
        Object.entries(this.overrideChildrenPlugin).map(async ([handle, value]) => {
          // check the entry and remove the item
          // the override checkbox for the item was pressed
          if (value) {
            // remove the item
            await Promise.all(childrenArray.map(async (item) => {
              let pluginData = null;
              if (item.type === 'folder') {
                pluginData = {
                  folder_id: item.id,
                  project_id: null,
                };
                await this.$apollo.mutate({
                  mutation: DeletePluginEntryForFolder,
                  variables: {
                    id: item.id,
                    handle,
                  },
                });
              } else {
                pluginData = {
                  project_id: item.id,
                  folder_id: null,
                };
                await this.$apollo.mutate({
                  mutation: DeletePluginEntryForProject,
                  variables: {
                    id: item.id,
                    handle,
                  },
                });
              }
              // insert the new item
              if (allCurrentPluginData[handle]) {
                // if entry exists insert data into hasura
                const pluginDataItem = allCurrentPluginData[handle];
                pluginData = {
                  ...pluginData,
                  settings: pluginDataItem.settings,
                  handle,
                  enabled: pluginDataItem.enabled,
                };
                await this.$apollo.mutate({
                  mutation: InsertPlugin,
                  variables: {
                    object: pluginData,
                  },
                });
              }
            }));
          }
        });
      }
    },
    async saveAllChildrenCIValues() {
      if (!isEmpty(this.overrideChildrenCI)) {
        // check which entry is true
        const data = {};
        Object.entries(this.overrideChildrenCI).forEach(([key, value]) => {
          if (value) {
            switch (key) {
              case 'logo':
                data.logo = this.corporateIdentity.logo;
                break;
              case 'favicon':
                data.favicon = this.corporateIdentity.favicon;
                break;
              case 'color':
                data._customColor = this.corporateIdentity._customColor;
                break;
              case 'typo':
                if (this.corporateIdentity.disableGoogleFont) {
                  data.disableGoogleFont = this.corporateIdentity.disableGoogleFont;
                }
                if (this.corporateIdentity._customFonts) {
                  data._customFonts = this.corporateIdentity._customFonts;
                }
                if (this.corporateIdentity.defaultFonts) {
                  data.defaultFonts = this.corporateIdentity.defaultFonts;
                }
                data.enableExtendTypo = this.corporateIdentity.enableExtendTypo;
                data.extendTypo = this.corporateIdentity.extendTypo;
                break;
              case 'button':
                data.buttons = this.corporateIdentity.buttons;
                break;
              default:
                console.log('override children key does not exist');
            }
          }
        });
        const childrenArray = await this.getAllDescendentItems();
        await Promise.all(childrenArray.map(async (item) => {
          if (item.type === 'folder') {
            await this.$apollo.mutate({
              mutation: UpdateFoldersCIData,
              variables: {
                folderId: item.id,
                data,
              },
            });
          }
          if (item.type === 'project') {
            await this.$apollo.mutate({
              mutation: UpdateProjectsCIData,
              variables: {
                projectId: item.id,
                data,
              },
            });
          }
        }));
      }
    },
    async getAllDescendentItems() {
      // get all the data
      const childrenArray = [];
      const { data: children } = await this.$apollo.query({
        query: GetAllDescendantsOfItem,
        variables: {
          itemPath: this.currentItemObject.itemPath,
        },
        fetchPolicy: 'network-only',
      });
      children.folder_descendants.forEach((folder) => {
        // check if the folder is not the current element
        if (folder.folder_id !== this.currentItemObject.id) {
          childrenArray.push({ id: folder.folder_id, type: 'folder' });
        }
      });
      children.project_descendants.forEach((project) => {
        childrenArray.push({ id: project.project_id, type: 'project' });
      });
      return childrenArray;
    },
    async savePluginSettingsInDB() {
      Object.entries(this.updatedPluginData).map(
        async ([handle, item]) => {
          try {
            if (handle === 'plugin_password_protection') {
              const pluginData = {
                folder_id: this.currentItemObject.id,
                enabled: item.enabled,
                title: item.settings.title,
                text: item.settings.text,
                buttonText: item.settings.buttonText,
                bgLink: (item.settings?.bgLink?.url) ? item.settings.bgLink.url : '',
                color: item.settings.color,
              };
              // set the password only if there is a new default string!
              if (item.settings.hash !== '****!') {
                pluginData.password = item.settings.hash;
              }
              await this.$apollo.mutate({
                mutation: SetPluginPasswordProtection,
                variables: {
                  pluginData,
                },
              });
            } else if (item.dataFromDb) {
              await this.$apollo.mutate({
                mutation: UpdateFolderPlugin,
                variables: {
                  folderId: this.currentItemObject.id,
                  handle,
                  isActive: item.enabled,
                  settings: item.settings,
                },
              });
            } else {
              await this.$apollo.mutate({
                mutation: InsertPlugin,
                variables: {
                  object: {
                    folder_id: this.currentItemObject.id,
                    project_id: null,
                    handle: item.handle,
                    enabled: item.enabled,
                    settings: item.settings,
                  },
                },
              });
            }
            this.updatedPluginData = {};
            this.forceRerenderPluginComponent();
          } catch (ex) {
            console.log(ex);
          }
        },
      );
    },
    getParentFolderIdOfFolderByBreadcrumb(breadcrumbsObject) {
      if (breadcrumbsObject.length > 1) {
        return breadcrumbsObject[breadcrumbsObject.length - 2].folder_id;
      }
      return null;
    },
    overrideAllPluginChildren(value) {
      let isDataChanged = value;
      if (value) {
        this.overrideChildrenPlugin = {
          plugin_facebook_pixel: true,
          plugin_linkedin_pixel: true,
          plugin_usercentrics: true,
          plugin_plausible: true,
          plugin_google_tag_manager: true,
          plugin_google_analytics: true,
          plugin_matomo_analytics: true,
          plugin_password_protection: false,
          plugin_exit_intent: true,
          plugin_gated_content: true,
          plugin_disqus: true,
          plugin_floating_icon_menu: true,
          plugin_cookies_consent: true,
          plugin_cookie_bot: true,
          plugin_ccm19: true,
          plugin_123_chat: true,
          plugin_variables: true,
          plugin_intro: true,
        };
      } else {
        this.overrideChildrenPlugin = {
          plugin_facebook_pixel: false,
          plugin_linkedin_pixel: false,
          plugin_usercentrics: false,
          plugin_plausible: false,
          plugin_google_tag_manager: false,
          plugin_google_analytics: false,
          plugin_matomo_analytics: false,
          plugin_password_protection: false,
          plugin_exit_intent: false,
          plugin_gated_content: false,
          plugin_disqus: false,
          plugin_floating_icon_menu: false,
          plugin_cookies_consent: false,
          plugin_cookie_bot: false,
          plugin_ccm19: false,
          plugin_123_chat: false,
          plugin_variables: false,
          plugin_intro: false,
        };
      }
      if (this.checkIfPluginDataChanged()) {
        isDataChanged = true;
      }
      this.folderSettingsChanged(isDataChanged);
    },
    overrideChildrenWithPlugin(name, value) {
      this.overrideChildrenPlugin[name] = value;
      let isDataChanged = value;
      if (!value) {
        Object.entries(this.overrideChildrenPlugin).forEach((item) => {
          if (item[1]) {
            isDataChanged = true;
          }
        });
      }
      if (this.checkIfPluginDataChanged()) {
        isDataChanged = true;
      }
      this.folderSettingsChanged(isDataChanged);
    },
    overrideAllCIChildren(value) {
      let isDataChanged = value;
      if (value) {
        this.overrideChildrenCI = {
          logo: true,
          favicon: true,
          color: true,
          typo: true,
          button: true,
        };
      } else {
        this.overrideChildrenCI = {
          logo: false,
          favicon: false,
          color: false,
          typo: false,
          button: false,
        };
      }
      if (!isEqual(this.corporateIdentity, this.corporateIdentityOriginal)) {
        isDataChanged = true;
      }
      this.folderSettingsChanged(isDataChanged);
    },
    overrideChildrenWithCI(name, value) {
      this.overrideChildrenCI[name] = value;
      let isDataChanged = value;
      if (!value) {
        Object.entries(this.overrideChildrenCI).forEach((item) => {
          if (item[1]) {
            isDataChanged = true;
          }
        });
      }
      if (!isEqual(this.corporateIdentity, this.corporateIdentityOriginal)) {
        isDataChanged = true;
      }
      this.folderSettingsChanged(isDataChanged);
    },
    updateCISettings(ciData) {
      this.corporateIdentity = ciData;
      let isDataChanged = false;
      if (!isEqual(ciData, this.corporateIdentityOriginal)) {
        isDataChanged = true;
      }
      this.folderSettingsChanged(isDataChanged);
    },
    updateFolderPublicationSettings(handle, fpData) {
      if (handle === 'language') {
        this.folderData.language_id = fpData.id;
      } else {
        this.folderData[handle] = fpData;
      }
      let isDataChanged = false;
      if (!isEqual(this.folderData, this.folderDataOriginal)) {
        isDataChanged = true;
      }
      this.folderSettingsChanged(isDataChanged);
    },
    folderSettingsChanged(isDataChanged = true) {
      this.setDataChangedInCache({
        pageType: this.pageType,
        isDataChanged,
        isSubmittedButtonPressed: false,
        isCancelButtonPressed: false,
        isDataPublished: false,
      });
    },
    updatePluginSettings(pluginData) {
      this.updatedPluginData = {
        ...this.updatedPluginData,
        ...pluginData,
      };
      this.folderSettingsChanged(this.checkIfPluginDataChanged());
    },
    checkIfPluginDataChanged() {
      let isDataChanged = false;
      // go through all keys of object
      // eslint-disable-next-line array-callback-return
      Object.entries(this.updatedPluginData).map((element) => {
        const handle = element[0];
        const pluginSettings = element[1];
        // if there is no entry in the DB and the plugin is enabled
        if (!pluginSettings.dataFromDb && pluginSettings.enabled) {
          isDataChanged = true;
        } else if (pluginSettings.dataFromDb && this.pluginDataFromDB[handle] !== undefined) {
          // check if plugin was disabled before
          if (this.pluginDataFromDB[handle].enabled !== pluginSettings.enabled) {
            isDataChanged = true;
          } else if (pluginSettings.enabled) {
            // check if plugin settings is array (because of intro and variables) and
            // check if the length of the array is different delete function
            if (
              Array.isArray(pluginSettings.settings)
              && pluginSettings.settings.length !== this.pluginDataFromDB[handle].settings.length
            ) {
              isDataChanged = true;
            }
            // eslint-disable-next-line array-callback-return
            Object.entries(pluginSettings.settings).map((elem) => {
              const settingsValue = elem[1];
              const settingsKey = elem[0];
              if (settingsKey === 'hash') {
                if (settingsValue !== '****!'
                  && settingsValue !== this.pluginDataFromDB[handle].settings[settingsKey]) {
                  isDataChanged = true;
                }
              } else if (settingsKey === 'imageLink') {
                if (!isEqual(this.pluginDataFromDB[handle].settings[settingsKey], settingsValue)) {
                  isDataChanged = true;
                }
              } else if (!isEqual(
                settingsValue,
                this.pluginDataFromDB[handle].settings[settingsKey],
              )) {
                if (!isEqual(
                  settingsValue,
                  this.pluginDataFromDB[handle].settings[settingsKey],
                )) {
                  isDataChanged = true;
                }
              }
            });
          }
        }
      });
      return isDataChanged;
    },
    forceRerenderPluginComponent() {
      this.componentKey += 1;
    },
  },
};
</script>
